import { render, staticRenderFns } from "./Overview.vue?vue&type=template&id=7a2264f1"
import script from "./Overview.vue?vue&type=script&lang=js"
export * from "./Overview.vue?vue&type=script&lang=js"
import style0 from "./Overview.vue?vue&type=style&index=0&id=7a2264f1&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WidgetKeyValue: require('/app/components/Widget/WidgetKeyValue.vue').default,ChartPie: require('/app/components/Chart/ChartPie.vue').default,EmptyState: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/EmptyState/EmptyState.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellDouble: require('/app/components/Widget/WidgetLayoutRowCellDouble.vue').default,WidgetLayoutRowSpacer: require('/app/components/Widget/WidgetLayoutRowSpacer.vue').default,WidgetLayoutRowCellFourths: require('/app/components/Widget/WidgetLayoutRowCellFourths.vue').default,WidgetTable: require('/app/components/Widget/WidgetTable.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default,WidgetLayout: require('/app/components/Widget/WidgetLayout.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default})
